


import React from "react";
import "../../styles/about/aboutteam.css";
import Team1 from "../../Assets/default.png";
import TedyImg from "../../Assets/FH1A5911.png";
import Fade from "react-reveal/Fade";

const AboutTeam = () => {
  return (
    <div>
      <section className="flex flex-col items-center justify-center min-h-screen">
        <Fade bottom duration={1500}>

          <div className="mb-8 text-center">
            {/* Our <span className="text-lg font-semibold">Team</span> */}
          </div>
          <div className="mb-8 text-center">
            <h1 className="mt-5 text-3xl font-bold">Meet our team members</h1>

          </div>
          <div className="grid grid-cols-1 gap-8 mb-12 md:grid-cols-2 lg:grid-cols-4">
            <div className="text-center">
              <img src={TedyImg} alt="Tewodros Atnafu" className="object-cover w-64 h-64 mx-auto mb-4 rounded-full" />
              <h3 className="text-xl font-semibold">Tewodros Atnafu</h3>
              <p className="text-gray-600">CEO & Founder</p>
            </div>
            <div className="text-center">
              <img src={Team1} alt="Selam T/haimanot" className="object-cover w-64 h-64 mx-auto mb-4 rounded-full" />
              <h3 className="text-xl font-semibold">Selam T/haimanot</h3>
              <p className="text-gray-600">COO</p>
            </div>
            <div className="text-center">
              <img src={Team1} alt="Samrawit Atnafu" className="object-cover w-64 h-64 mx-auto mb-4 rounded-full" />
              <h3 className="text-xl font-semibold">Samrawit Atnafu</h3>
              <p className="text-gray-600">Project Lead</p>
            </div>
            <div className="text-center">
              <img src={Team1} alt="Tekalegne Defar" className="object-cover w-64 h-64 mx-auto mb-4 rounded-full" />
              <h3 className="text-xl font-semibold">Tekalegne Defar</h3>
              <p className="text-gray-600">Technical Lead</p>
            </div>
          </div>
          <button className="px-4 py-2 text-white rounded About__Team__btn hover:bg-blue-600">
            <a className="no-underline" href="/meet-the-team">Meet the Team</a>
          </button>
        </Fade>
      </section>
    </div>
  );
};

export default AboutTeam;

