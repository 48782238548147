import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosArrowBack } from "react-icons/io";

function NewsDetail() {
  // Access URL parameter (e.g., news ID)
  const { id } = useParams();
  const [newsItem, setNewsItem] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchUsers = async () => {
    try {
      const data = localStorage.getItem("passUser");
      const token = JSON.parse(data).accessToken;
      const res = await axios.get(`https://api.tewostech.com/api/news/${id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(res.data.data);
      setNewsItem(res.data.data);
    } catch (error) {
      toast.error("An error occurred!", {
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [dispatch]);

  const handleBack = () => {
    navigate("/admin/news");
  };

  return (
    <div className="m-2">
      <div className="w-full mx-auto bg-white rounded-lg">
        <div className="flex items-center justify-start px-4 py-2 border-b border-gray-200">
          <button onClick={(event) => handleBack()}>
            <IoIosArrowBack size={35} />
          </button>
          <h2 className="ml-4 text-xl font-semibold">News Detail</h2>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-1 gap-4">
            <div>
              <h2 className="text-xl font-semibold">Title</h2>
              <div>{newsItem?.headline || "N/A"}</div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 mt-4">
            <div>
              <h2 className="text-xl font-semibold">Content</h2>
              <div>{newsItem?.content || "N/A"}</div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-4">
            <div>
              <h2 className="text-xl font-semibold">Source</h2>
              <div>{newsItem?.source || "N/A"}</div>
            </div>
            <div>
              <h2 className="text-xl font-semibold">URL</h2>
              <div>{newsItem?.url || "N/A"}</div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-4">
            <div>
              <h2 className="text-xl font-semibold">Category</h2>
              <div>{newsItem?.category_id || "N/A"}</div>
            </div>
            <div>
              <h2 className="text-xl font-semibold">Publication Date</h2>
              <div>{newsItem?.publication_date || "N/A"}</div>
            </div>
          </div>
          <div className="mt-4">
            <h2 className="text-xl font-semibold">Content Images</h2>
            <div>
              {/* Display content images */}
              {newsItem?.images?.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Content Image ${index + 1}`}
                  className="mt-2 rounded"
                />
              )) || "No images available"}
            </div>
          </div>
        </div>
       
      </div>
    </div>
  );
}

export default NewsDetail;
