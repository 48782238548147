import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosArrowBack } from "react-icons/io";

function VacanyDetail() {
  const { id } = useParams();
  const [vacancy, setVacancy] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchUsers = async () => {
    try {
      const data = localStorage.getItem("passUser");
     const token = JSON.parse(data).accessToken;
     const res = await axios.get(
        `https://api.tewostech.com/api/vacancy/${id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(res.data);
      setVacancy(res.data);
    } catch (error) {
      toast.error("An error occurred!", {
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [dispatch]);

  const handleBack = () => {
    navigate("/admin/vaccancy");
  };

  return (
    <div className="container mx-auto">
    <section className="pt-2">
    <div className="flex items-center justify-start px-2 border-gray-200">
          <button onClick={(event) => handleBack()}>
            <IoIosArrowBack size={35} />
          </button>
          <h2 className="ml-4 text-xl font-semibold">Vaccany Detail</h2>
        </div>
        <div className="flex flex-col mt-5 transition-shadow duration-300 bg-white border rounded-lg shadow-md md:flex-row hover:shadow-lg">
            <div className="flex-1 p-4">
                <h5 className="font-bold text-center">{vacancy?.job_title || "N/A"}</h5>
                <h6 className="pt-4">
                    <strong className="text-sm">Department:</strong>
                    <span className="text-sm text-gray-600"> {vacancy?.department || "N/A"}</span>
                </h6>
                <h6 className="pt-4">
                    <strong className="text-sm">Location:</strong>
                    <span className="text-sm text-gray-600"> {vacancy?.location || "N/A"}</span>
                </h6>
                <h6 className="pt-4">
                    <strong className="text-sm">Employment Type:</strong>
                    <span className="text-sm text-gray-600"> {vacancy?.employment_type || "N/A"}</span>
                </h6>
            </div>
            <div className="border-l border-gray-300 md:h-auto"></div>
            <div className="flex-1 p-4 text-left ">
                <h6 >
                    <strong className="text-sm ">Description:</strong>
                    <span className="text-sm text-gray-600"> {vacancy?.job_description || "N/A"}</span>
                </h6>
                <h6 className="pt-4">
                    <strong className="text-sm">Qualifications:</strong>
                    <span className="text-sm text-gray-600"> {vacancy?.qualifications || "N/A"}</span>
                </h6>
                <h6 className="pt-4">
                    <strong className="text-sm">Benefits:</strong>
                    <span className="text-sm text-gray-600"> {vacancy?.benefits || "N/A"}</span>
                </h6>
                <h6 className="pt-4">
                    <strong className="text-sm">Responsibilities:</strong>
                    <span className="text-sm text-gray-600"> {vacancy?.responsibilities || "N/A"}</span>
                </h6>
                <h6 className="pt-4">
                    <strong className="text-sm">Salary From:</strong>
                    <span className="text-sm text-gray-600"> {vacancy?.salary_min || "N/A"} - {vacancy?.salary_max || "N/A"} ETB</span>
                </h6>
                <p className="pt-4">
                    <strong className="text-sm">Application Deadline : </strong>
                    <span className="text-sm text-red-600">{new Date(vacancy?.application_deadline || "N/A").toLocaleDateString()}</span>
                </p>
            </div>
        </div>
    </section>
</div>
  );
}

export default VacanyDetail;
