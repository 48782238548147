
import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import axios from "axios";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";

const Vacancy = () => {
    const dispatch = useDispatch();
    const [vacancies, setVacancies] = useState([]);

    const fetchVacancy = async () => {
        try {
          
            const res = await axios.get("https://api.tewostech.com/api/vacancy", {
                headers: {
                    'Content-Type': 'multipart/form-data',
                 
                }
            });
            setVacancies(res.data); 
        } catch (error) {
            toast.error('An error occurred!', {
                position: 'top-right'
            });
        }
    };

    useEffect(() => {
        fetchVacancy();
    }, [dispatch]);

    return (
        <div className="container mx-auto px-4 py-8">
            <section className="pt-5">
                <h3 className="text-center text-3xl font-bold pt-5">Job Vacancies</h3>
                <div className="vacancy-list grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mt-8">
                    {vacancies.map((vacancy) => (
                        <div key={vacancy.id} className="vacancy-card bg-white border border-gray-200 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                            <div className="text-left flex flex-col mb-4">
                                <h6 className="text-sm text-green-600 mb-2">{vacancy.employment_type}</h6>
                                <h6 className="text-sm font-semibold mb-2">{vacancy.job_title}</h6>
                                <h6 className="text-sm mb-2">{vacancy.location}</h6>
                                <button className=" text-left">
    <Link 
        to={`/vacancy/${vacancy.id}`} 
        state={{ vacancy }} 
        className="no-underline text-green-600"
    >
        View Job Detail
    </Link>
</button>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default Vacancy;
