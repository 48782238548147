import React, { useEffect, useRef, useState, useContext } from 'react'

import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import axios from "axios";

function UpdatePassword() {
    const userRef = useRef();
    const state = useSelector((state) => state);

    // request status
    const [loginStatus, setLoginStatus] = useState("idle");
    const navigate = useNavigate();

    const [errMsg, setErrMsg] = useState();
    const [success, setSuccess] = useState(false);

    const [users, setUsers] = useState({
        confirm_password: '',
        password: ''
    })
    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [users])

    const handleConfirmPasswordChange = (e) => {
        const confirm_password = e.target.value;
        setUsers({ ...users, confirm_password });
        validatePasswords(users.password, confirm_password);
    };

    const validatePasswords = (password, confirm_password) => {
        if (password !== confirm_password) {
            setErrMsg('Passwords do not match');
        } else {
            setErrMsg('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (users.password !== users.confirm_password) {
            setErrMsg('Passwords do not match');
            return;
        }
        const dataContainer = {
            password: users.password
        }
        try {
            console.log(dataContainer)
            const response = await axios.put("https://api.tewostech.com/api/user/0925357022/password",
                dataContainer
            );
            if (response.status === 200) {
                setUsers("");
                navigate("/login");
            }
        } catch (err) {
            setLoginStatus("failed");
        }
    }

    return (
        <>
            <div className='grid gap-4 m-4 '>
                <p class="text-gray-600 text-center mb-0">Update Password</p>
                <div className='min-h-[250px] border bg-white  px-5 pt-3 pb-2 rounded justify-self-center'>
                    <form onSubmit={handleSubmit}>
                        <div className='mb-4'>
                            <label htmlFor='password'>New Password</label>
                            <input type='password' ref={userRef} name='password' className='form-control' placeholder="New Password"
                                value={users.password} onChange={e => setUsers({ ...users, password: e.target.value })} required />
                        </div>
                        <div className='mb-4'>
                            <label htmlFor='confirm_pasword'>Confirm Password</label>
                            <input
                                type='password'
                                name='confirm_password'
                                className='form-control'
                                placeholder='Confirm New Password'
                                value={users.confirm_password}
                                onChange={handleConfirmPasswordChange}
                                required
                            />
                        </div>
                        {errMsg && <p className="mb-2 text-sm text-red-500">{errMsg}</p>}
                        <div className='mb-2'>
                            <button type="submit" class="w-full bg-red-500 text-white px-4 py-2 rounded-lg  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">Update Password</button>
                        </div>
                    </form>

                </div>
            </div>

        </>
    )
}

export default UpdatePassword