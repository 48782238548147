import React from "react";
import "../../styles/home/homeabout.css";
import HomeAboutImg from "../../Assets/about.png";

const HomeAbout = () => {
  return (
    <div>
      <div className=" sm:grid-cols-1 mb-[-10]">
        <div className="Abouts__Text">
          About <span className="Abouts__span">Us </span>
        </div>
      </div>

      <div className="grid sm:grid-cols-12 gap-2 m-4">
        <div className="min-h-[50px] rounded-md sm:col-span-6 pl-10">
          <p className="Vision__text">Vision</p>
          <h6 className="	font-nunito text-[#595566] font-normal">
            Established to deliver leading edge secure technologies, technical solutions and consulting services to businesses, organizations and governmental bodies, Tewos Solutions was founded with the vision to enable “secure access to any service, data and information anytime, anywhere”, providing its customers and business partners with robust, extensible and customizable security-enhanced solutions for information sharing, data exchange, services invocation and communication.
          </h6>
          <p className="Miss__header" >MISSION</p>
          <h6>
            <div  style={{ paddingBottom: '10px' }}>
              <p className="font-nunito text-[#595566] font-normal ">
              Tewos Solutions mission is to enhance the business operation of
              its clients by developing and/or implementing premium IT
              products and services. Tewos Solutions’ mission includes:
              </p>
            
            </div>

            <ul className="fancy-bullets">
              <li className="	font-nunito text-[#595566] font-normal">To deliver effective, efficient, reliable, accessible and secure Information technology solutions, products, and services that are relevant to the Ethiopian environment and fully support the country's needs.</li>
              <li className="	font-nunito text-[#595566] font-normal">To become a high impact provider of IT products and services in Africa.</li>
              <li className="	font-nunito text-[#595566] font-normal">Providing our employees with meaningful work and advancement opportunities.</li>
            </ul>
          </h6>
          {/* <button className="ReadMore__btn">
            <h3>
              <a className="no-underline" href="/about"> Read More</a>{" "}
            </h3>{" "}
          </button> */}
        </div>

        <div className="min-h-[50px] rounded-md sm:col-span-6">
          <div className="m-4">
          <img src={HomeAboutImg} alt="" className="w-full h-auto max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl" />
          </div>
          <div >
          </div>
        </div>
      </div>
      {/* </Fade> */}
    </div>

  );
};

export default HomeAbout;
