import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllUsers = createAsyncThunk(
  "fetch/locations", async (page = 1) => {
    try {
      const data = localStorage.getItem("passUser");
      const token =  JSON.parse(data).accessToken;
      console.log(token);
      //const token ="d88423c1b7d91ebe409fddd4199fb290c90cd271";
      const res = await axios.get("https://mob.tewostechsolutions.com/api/alluser", {
        headers: {
           'Content-Type': 'multipart/form-data',
           'Authorization': `Bearer ${token}` 
         }
      });
      console.log("the response from server is ones ", res.data, res.status);
      return res.data;
    } catch (error) {
      return error.code;
    }

  });

  export const getAdminReport = createAsyncThunk(
    "fetch/adminreports", async (page = 1) => {
      try {
        // const token ="d88423c1b7d91ebe409fddd4199fb290c90cd271";
        const data = localStorage.getItem("passUser");
      const token =  JSON.parse(data).accessToken;
      // console.log(token);
        const res = await axios.get("https://mob.tewostechsolutions.com/api/admin_reports", {
          headers: {
             'Content-Type': 'multipart/form-data',
             'Authorization': `Bearer ${token}`
           }
        });
        console.log("the response from server is ", res.data, res.status);
        return res.data;
      } catch (error) {
        return error.code;
      }
  
    });

  export const getReport = createAsyncThunk(
    "fetch/reports", async (page = 1) => {
      try {
        // const token ="d88423c1b7d91ebe409fddd4199fb290c90cd271";
        const data = localStorage.getItem("passUser");
      const token =  JSON.parse(data).accessToken;
      // console.log(token);
        const res = await axios.get("https://mob.tewostechsolutions.com/api/sms_report", {
          headers: {
             'Content-Type': 'multipart/form-data',
             'Authorization': `Bearer ${token}`
           }
        });
        console.log("the response from server is ", res.data, res.status);
        return res.data;
      } catch (error) {
        return error.code;
      }
  
    });


const userManagementSlice = createSlice(
  {
    name: "user",
    initialState: {
      isLoading: false,
      user: [],
      isError: false
    },
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(getAllUsers.fulfilled, (state, action) => {
        state.isLoading = true;
        //   state.data = action.payload;
        state.isLoading=true;
        console.log("added is ", action.payload)
        state.user = action.payload
      })
      .addCase(getReport.fulfilled, (state, action) => {
        //   state.data = action.payload;
        state.isLoading=true;
        console.log("added is ", action.payload)
        state.user = action.payload
      }).addCase(getAdminReport.fulfilled,(state,action)=>{
        state.isLoading=true;
        console.log("added is ", action.payload)
        state.user = action.payload
      })
               
    }
  }
);

export default userManagementSlice.reducer;