import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Menu from "./Componets/Menu/Menu";
import Footer from "./Componets/Footer/Footer";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Team from "./Pages/Team/Team";
import Contact from "./Pages/Contact/Contact";
import Service from "./Pages/Service/Service";
import Portifolio from "./Pages/Portifolio/Portifolio";
import Secondservice from "./Pages/Secondservice/Secondservice";
import Portifoliosecond from "./Pages/Portifoliosecond/Portifoliosecond";
import Record from "./Pages/Contact/record";
import News from "./Pages/News/News";

import AdminReportManagment from "./Admins/AdminReportManagment";
import AdminTotalSMSReport from "./Admins/admin_total_report";
import AdminDailySMSReport from "./Admins/admin_daily_sms_report";
import AdminMonthlySMSReport from "./Admins/admin_monthly_sms_report";
import AdminYearlySMSReport from "./Admins/admin_yearly_sms_report";
import AdminLayout from "./Shared/AdminLayout";
import NewList from "./Admins/News/NewList";
import Vaccancy from "./Admins/Vaccancy/Vacancy";
import AddNews from "./Admins/News/AddNews";
import AddVaccany from "./Admins/Vaccancy/AddVaccany";
import NewsDetail from "./Admins/News/NewsDetail";
import VacanyDetail from "./Admins/Vaccancy/VaccanyDetail";
import Vacancy from "./Pages/vacancy/vacancy";
import VacancyDetail from "./Pages/vacancy/vacancy_detail";
import LoginPage from "./Admins/LoginPage";
import UpdateNews from "./Admins/News/UpdateNews";
import UpdateVaccany from "./Admins/Vaccancy/UpdateVaccany";
import Protected from "./auth/Protected";
import "./App.css";
import SendOtp from "./Admins/forget_password/SendOtp";
import RegisterOtp from "./Admins/forget_password/Register_otp";
import UpdatePassword from "./Admins/forget_password/UpdatePassword";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on initial load
  }, []);

  return (
    <div className="App">
      <Routes>
        {/* Admin Routes */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/sendotp" element={<SendOtp />} />
        <Route path="/registerOtp" element={<RegisterOtp />} />
        <Route path="/updatePassword" element={<UpdatePassword />} />
        <Route
          path="/admin"
          element={
            <Protected role="User">
              <AdminLayout />
            </Protected>
          }
        >
          <Route
            index
            element={
              <Protected role="User">
                <AdminReportManagment />{" "}
              </Protected>
            }
          />
          <Route
            path="totalsmsreport"
            element={
              <Protected role="User">
                <AdminTotalSMSReport />{" "}
              </Protected>
            }
          />
          <Route
            path="dailysmsreport"
            element={
              <Protected role="User">
                <AdminDailySMSReport />{" "}
              </Protected>
            }
          />
          <Route path="monthlysmsreport" element={<AdminMonthlySMSReport />} />
          <Route path="yearlysmsreport" element={<AdminYearlySMSReport />} />
          <Route
            path="news"
            element={
              <Protected role="User">
                <NewList />{" "}
              </Protected>
            }
          />
          <Route
            path="add-news"
            element={
              <Protected role="User">
                <AddNews />
              </Protected>
            }
          />
          <Route
            path="vaccancy"
            element={
              <Protected role="User">
                <Vaccancy />
              </Protected>
            }
          />
          <Route
            path="add-vaccancy"
            element={
              <Protected role="User">
                <AddVaccany />{" "}
              </Protected>
            }
          />
          <Route
            path="new-detail/:id"
            element={
              <Protected role="User">
                <NewsDetail />{" "}
              </Protected>
            }
          />
          <Route
            path="news-update/:id"
            element={
              <Protected role="User">
                <UpdateNews />
              </Protected>
            }
          />
          <Route
            path="vaccancy-detail/:id"
            element={
              <Protected role="User">
                <VacanyDetail />
              </Protected>
            }
          />
          <Route
            path="vaccancy-update/:id"
            element={
              <Protected role="User">
                <UpdateVaccany />
              </Protected>
            }
          />
        </Route>

        {/* Non-Admin Routes */}
        <Route
          path="/*"
          element={
            <>
              <Menu />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/meet-the-team" element={<Team />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/Service" element={<Service />} />
                <Route path="/Portfolio" element={<Portifolio />} />
                <Route
                  path="/Portfoliodetails"
                  element={<Portifoliosecond />}
                />
                <Route path="/Service-offer" element={<Secondservice />} />
                <Route path="/record" element={<Record />} />
                <Route path="/News" element={<News />} />
                <Route path="/vacancy" element={<Vacancy />}></Route>
                <Route path="/vacancy/:id" element={<VacancyDetail />} />
                {/* <Route path="/content" element={<ContentDisplay />} /> */}
              </Routes>
              <Footer />
            </>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
