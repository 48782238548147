import React from "react";
import "../../styles/about/aboutteam.css";
import Fade from "react-reveal/Fade";

const teamMembers = [
  {
    name: "Muluken Zeleke",
    title: "Front-End Developer",
    image: require("../../Assets/team/muluken.png"),
  },
  {
    name: "Bethemariam Asrat",
    title: "Back-End Developer",
    image: require("../../Assets/team/beti_prev_ui (1).png"),
  },
  {
    name: "Solomon Kassahun",
    title: "Front-End Developer",
    image: require("../../Assets/team/Solomon Kasahun.png"),
  },
  {
    name: "Awoke Guadie",
    title: "Front-End Developer",
    image: require("../../Assets/team/aweke.jpg"),
  },
  {
    name: "Amanuel Alemayehu",
    title: "Quality Control",
    image: require("../../Assets/team/Amanuel Alemayehu.png"),
  },
  {
    name: "Fasika Habtu",
    title: "Quality Control",
    image: require("../../Assets/team/fasika_prev_ui (1).png"),
  },
  {
    name: "Amanuel Beyena",
    title: "Front-End Developer",
    image: require("../../Assets/team/Amanuel beyena.png"),
  },
  {
    name: "Ermiyas Sene",
    title: "QC Team Leader",
    image: require("../../Assets/team/ermiyas.png"),
  },
  {
    name: "Mehari Melaku",
    title: "Back-End Developer",
    image: require("../../Assets/team/mehari.png"),
  },
  {
    name: "Melkie Kindie",
    title: "Back-End Team Leader",
    image: require("../../Assets/team/melkie_prev_ui.png"),
  },
  {
    name: "Weldsh Aweke",
    title: "Front-End Team Leader",
    image: require("../../Assets/team/weldish.jpg"),
  },
  {
    name: "yosef berhanu ehetu",
    title: "Quality Control",
    image: require("../../Assets/team/yosef.png"),
  },
  {
    name: "Natnael Legesse",
    title: "Back-End Developer",
    image: require("../../Assets/team/nati_prev_ui (1).png"),
  },
  {
    name: "Azemeraw Aschale",
    title: "Back-End Developer",
    image: require("../../Assets/team/azi_prev_ui.png"),
  },

];

const AboutTeam = () => {
  return (
    <div className="grid">
      {/* <section className="About__teams"> */}
      <Fade bottom duration={1500}>
        <div className="Header">
          <h1>Meet our team members</h1>
        </div>
        <div className="grid grid-cols-1 gap-4 p-5  sm:grid-cols-4">
          {teamMembers.map((member, index) => (
            <Fade bottom duration={1000} key={index}>
              <div className="flex flex-col items-center min-h-[50px] rounded-md border-gray-700 p-4 shadow-2xl">
                <img
                  src={member.image}
                  alt={member.name}
                  style={{
                    width: '100%',
                    maxWidth: '200px',
                    height: '70%',
                  }}
                />
                <h4 className="mt-2 text-center">{member.name}</h4>
                <p className="mt-1 text-center">{member.title}</p>
              </div>
            </Fade>
          ))}
        </div>

      </Fade>
      {/* </section> */}
    </div>
  );
};

export default AboutTeam;