import ReactDOM from "react-dom";
import React from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from "./App";
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { store } from './app/store.js';
;


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
        <ToastContainer />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);