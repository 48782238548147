

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import { SearchIcon } from '@heroicons/react/solid';
import { DateTimeFormat } from '../../utils/DateTimeFormat';
import newimage from "../../assest/tewos11.jpg";

const News = () => {
  const [news, setNews] = useState([]);
  const [filteredNews, setFilteredNews] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); 

  const [currentPage, setCurrentPage] = useState(1);
  const [newsPerPage] = useState(5);

  const fetchNews = async () => {
    try {
      const res = await axios.get("https://api.tewostech.com/api/news", {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      setNews(res.data.data);
      setFilteredNews(res.data.data);
    } catch (err) {
      console.error(err);
      toast.error("Failed to fetch news");
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);

  useEffect(() => {
    const results = news.filter((item) =>
      item.headline.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.content.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredNews(results);
  }, [searchTerm, news]);

  // Pagination logic
  const indexOfLastNews = currentPage * newsPerPage;
  const indexOfFirstNews = indexOfLastNews - newsPerPage;
  const currentNews = filteredNews.slice(indexOfFirstNews, indexOfLastNews);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const toggleReadMore = (index) => {
    setFilteredNews(prevNews =>
      prevNews.map((item, i) =>
        i === index ? { ...item, isExpanded: !item.isExpanded } : item
      )
    );
  };

  const truncateText = (text, isExpanded) => {
    if (isExpanded) return text;
    const words = text.split(" ");
    return words.length > 130 ? words.slice(0, 130).join(" ") + "..." : text;
  };

  return (
    <div className="p-5">
      <div className="relative flex justify-end mt-16 mb-5">
        <div className="relative w-64">
          <input
            type="text"
            placeholder="Search news..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 pl-4 pr-10 border border-gray-300 rounded"
          />
          <SearchIcon className="absolute right-3 top-2.5 w-5 h-5 text-gray-500" />
        </div>
      </div>

      <div className="space-y-5">
        {currentNews.length > 0 ? (
          currentNews.map((item, index) => (
            <div key={index} className="flex flex-col items-start p-4 bg-white rounded-lg md:flex-row">
              <div className="flex-shrink-0 mb-4 md:mb-0 md:mr-4">
                {item.medias && item.medias.length > 0 ? (
                  <img
                    src={`https://api.tewostech.com/storage/newsmedias/${item.medias[0].file_path}`}
                    alt="News"
                    className="object-cover w-full h-auto max-w-xs rounded shadow-lg"
                  />
                ) : (
                  <img src={newimage} alt="Default News" className="object-cover w-full h-auto max-w-xs rounded shadow-lg" />
                )}
                <div className="flex justify-center mt-3 space-x-6">
                  <a href="#" className="flex items-center" onClick={(e) => e.preventDefault()}>
                    <i className="text-lg bi bi-share-fill"></i>
                  </a>
                  <a
                    href={`https://telegram.me/share/url?url=https://tewostech.com/news`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/8/82/Telegram_logo.svg"
                      alt="Telegram"
                      className="w-6 h-6"
                    />
                  </a>
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=https://tewostech.com/news`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
                      alt="Facebook"
                      className="w-6 h-6"
                    />
                  </a>
                  <a
                    href={`https://www.instagram.com/sharer/sharer.php?u=https://tewostech.com/news`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png"
                      alt="Instagram"
                      className="w-6 h-6"
                    />
                  </a>
                </div>
                <div className="flex-1 pt-2">
                  <p className="pt-2 text-xs text-gray-400"><strong>Source: </strong><span className="text-blue-400">{item.source}</span></p>
                  <p className="text-xs text-gray-400">
                    <strong>Publication Date:</strong> 
                    <span className="text-blue-400">{DateTimeFormat(item.publication_date)}</span>
                  </p>
                </div>
              </div>
              <div className="flex-1">
                <h2 className="mb-2 text-xl text-gray-800">{item.headline}</h2>
                <p className="mb-3 text-sm font-thin leading-relaxed text-gray-500">
                  {truncateText(item.content, item.isExpanded)}
                </p>
                {item.content.split(" ").length > 130 && (
                  <button
                    onClick={() => toggleReadMore(index)}
                    className="text-sm text-blue-500 hover:underline"
                  >
                    {item.isExpanded ? "Read Less" : "Read More"}
                  </button>
                )}
              </div>
            </div>
          ))
        ) : (
          <p className="mt-5 text-center text-gray-500">No results found.</p>
        )}
      </div>

   
      <Pagination
        newsPerPage={newsPerPage}
        totalNews={filteredNews.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  );
};


const Pagination = ({ newsPerPage, totalNews, paginate, currentPage }) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalNews / newsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="flex justify-center space-x-2 mt-4">
        {pageNumbers.map((number) => (
          <li key={number}>
            <button
              onClick={() => paginate(number)}
              className={`px-3 py-1 rounded ${number === currentPage ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
            >
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default News;
