import React, { useEffect, useRef, useState, useContext } from 'react'
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";

const SendOtp = () => {
  // const { setAuth } = useContext(AuthContext);
  const errRef = useRef();
  const state = useSelector((state) => state);

  const navigate = useNavigate();

  const [errMsg, setErrMsg] = useState();
  const [success, setSuccess] = useState(false);

  const [users, setUsers] = useState({
    phone_number: '',
  })
  useEffect(() => {
  }, [])

  useEffect(() => {
    setErrMsg('');
  }, [users])

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const respone = await axios.get(`https://api.tewostech.com/api/user/${users.phone_number}/exists`, {
      });
      console.log(respone.status);
      navigate('/registerOtp')
      toast.success('Forget successfully!', {
        position: 'top-right'
      });
      // fetchUsers(); // Refresh the list after deletion
    } catch (error) {
      toast.error('An error occurred ', {
        position: 'top-right'
      });
    }
  }
  return (
    <div className='grid gap-4 m-4 '>
      <p class="text-gray-600 text-center mb-0">Place Enter Phone number</p>
      <div className='min-h-[200px] border  px-5 pt-3 pb-2 rounded  justify-self-center '>
        <p ref={errRef} className={errMsg ? "errmsg" :
          "offscreen"} aria-live="assertive">{errMsg}</p>
        {/* <h1>Login</h1> */}
        <form onSubmit={handleSubmit}>
          <div className='mb-4'>

            {/* <label htmlFor='phone'>Phone Number</label> */}
            <input type='text' name='phone' className='form-control' placeholder="Enter phone number"
              value={users.phone_number} onChange={e => setUsers({ ...users, phone_number: e.target.value })} required />
          </div>

          <div className='mb-2'>
            <button type="submit" class="w-full bg-orange-600 text-white px-4 py-2 rounded-lg  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">Send Code</button>
          </div>
        </form>

      </div>
    </div>
  )
}

export default SendOtp