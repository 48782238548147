
import React from "react";
import Fade from "react-reveal/Fade";

const AboutNumber = () => {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {/* Number */}
      <Fade bottom duration={1500}>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-40 ">
          <div className="no1 flex flex-col items-center">
            <div className="Sup flex items-center justify-center text-xl md:text-2xl">
              13<sup>+</sup>
            </div>
            <p className="lorem text-center text-sm md:text-base mt-2">
              Software Developments
            </p>
          </div>
          <div className="no2 flex flex-col items-center">
            <div className="Sup flex items-center justify-center text-xl md:text-2xl">
              110
            </div>
            <p className="lorem text-center text-sm md:text-base mt-2">
              Application Management
            </p>
          </div>
          <div className="no3 flex flex-col items-center">
            <div className="Sup flex items-center justify-center text-xl md:text-2xl">
              99<sup>%</sup>
            </div>
            <p className="lorem text-center text-sm md:text-base mt-2">
              Quality Assurance
            </p>
          </div>
          <div className="no4 flex flex-col items-center">
            <div className="Sup flex items-center justify-center text-xl md:text-2xl">
              110<sup>+</sup>
            </div>
            <p className="lorem text-center text-sm md:text-base mt-2">
              Application Development
            </p>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default AboutNumber;
