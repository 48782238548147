import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [forms, setForms] = useState({
    phone_number: "+251",
    password: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataContainer = {
        phone_number: "0" + forms.phone_number.substring(4),
        password: forms.password,
      };
      const form = e.currentTarget;
      if (form.checkValidity()) {
        try {
          // console.log(dataContainer);
          const res = await axios.post(
            "https://api.tewostech.com/api/login",
            dataContainer
          );
          if (res.data) {
            let role;
            if (res.data.role === "User" || res.data.role == null) {
              role = "User";
            } else {
              role = res.data.role;
            }
            var localStorageData = {
              id: res.data.user.id,
              accessToken: res.data.access_token,
              first_name: res.data.user.first_name,
              last_name: res.data.user.last_name,
              phone_number: res.data.user.phone_number,
              avatar: res.data.user.avatar,
              role: role,
            };
            localStorage.setItem("passUser", JSON.stringify(localStorageData));
            // if (res.data.role === "Bolo") {
            //   navigate("/pages");
            // } else if (res.data.role === "User" || res.data.role == null) {
            //   navigate("/user");
            // } else if (res.data.role === "Admin") {
            //   navigate("/admin");
            // }

            // window.location.reload();
            navigate("/admin");
            // onClose();
            toast.success("Login Successfully...!", {
              position: "top-right",
            });
          }
        } catch (error) {
          return error.code;
        }
        // dispatch(boloRegisteration(dataContainer));
        // Show success alert
      }
    } catch (error) {
      // Handle any error that occurs during form submission
      console.error(error);
    }
  };
  const handleInputChange = (event) => {
    setForms({
      ...forms,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <div className="grid gap-4 m-4 ">
        <div className="flex min-h-[20px] justify-self-end">
          <div className="container">{/* <LanguageSresctor /> */}</div>
        </div>
        <div className="min-h-[10px] justify-self-center">
          {/* <img className="inline-block w-12 h-12 rounded-full ring-2 ring-white" src={logo} alt="" /> */}
        </div>
        <h2 className="mb-0 text-2xl font-semibold text-center">Login</h2>
        <p className="mb-0 text-center text-gray-600">Welcome to Tewos Tech</p>
        <div className="min-h-[100px] justify-self-center">
          <div className="min-h-[100px] border bg-white shadow px-5 pt-3 pb-2 rounded">
            <form className="py-1" onSubmit={handleSubmit}>
              <div className="mb-4">
                <input
                  type="text"
                  name="phone_number"
                  className="form-control"
                  placeholder="Phone number"
                  value={forms.phone_number}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-4">
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  placeholder="password"
                  value={forms.password}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-2">
                <button
                  type="submit"
                  className="w-full px-4 py-2 text-white bg-red-600 rounded-lg bg-orange focus:outline-none focus:ring-2 focus:ring-opacity-50"
                >
                  Login
                </button>
              </div>
            </form>
            <div className="mb-2 min-h-[20px] justify-self-center">
              <span className="justify-center line">
                <a href="sendotp" className="text-orange text-decoration-none">
                  Forget Password
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function usernameVerify(values, error = {}) {
  if (!values.phone_number) {
    error.phone_number = toast.error("Phonenumber Required...!");
  } else if (values.phone_number.includes(" ")) {
    error.phone_number = toast.error("Invalid Phonenumber...!");
  } else if (!values.password) {
    error.password = toast.error("Password Required...!");
  } else if (values.password.includes(" ")) {
    error.password = toast.error("Wrong Password...!");
  } else if (values.password.length < 4) {
    error.password = toast.error(
      "Password must be more than 4 characters long"
    );
  }

  return error;
}

export default LoginPage;
