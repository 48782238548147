
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const fetchCustomers = createAsyncThunk(
  "fetch/locations", async (page = 1) => {
    try {
      const data = localStorage.getItem("passUser");
      const token =  JSON.parse(data).accessToken;
      console.log(token);
      //const token ="d88423c1b7d91ebe409fddd4199fb290c90cd271";
      const res = await axios.get("https://mob.tewostechsolutions.com/api/yenebolo/", {
        headers: {
           'Content-Type': 'multipart/form-data',
           'Authorization': `Bearer ${token}`
         }
      });
      console.log("the response from server is ", res.data, res.status);
      return res.data;
    } catch (error) {
      return error.code;
    }

  });

  export const getAllUsers = createAsyncThunk(
    "fetch/locations", async (page = 1) => {
      try {
        const data = localStorage.getItem("passUser");
        const token =  JSON.parse(data).accessToken;
        console.log(token);
        //const token ="d88423c1b7d91ebe409fddd4199fb290c90cd271";
        const res = await axios.get("https://mob.tewostechsolutions.com/api/alluser", {
          headers: {
             'Content-Type': 'multipart/form-data',
             'Authorization': `Bearer ${token}`
           }
        });
        console.log("the response from server is ", res.data, res.status);
        return res.data;
      } catch (error) {
        return error.code;
      }
  
    });
    export const updateCustomers = createAsyncThunk(
      "upate/locations",
      async ({customerData }) => {
        try {
          console.log(customerData.id)
          const res = await axios.put(`https://mob.tewostechsolutions.com/api/yenebolo/${customerData.id}/`,customerData);
          return res.data;
        } catch (error) {
          return error.code;
        }
      }
    );
    
  export const addCustomers = createAsyncThunk(
    "add/locations",
    async (customerData, { rejectWithValue }) => {
      try {
        const data = localStorage.getItem("passUser");
        const token =  JSON.parse(data).accessToken;
        const res = await axios.post("https://mob.tewostechsolutions.com/api/yenebolo", customerData, {
          headers: {
            "content-type": "application/json",
            'Authorization': `Bearer ${token}`
          }
        });
        console.log("data it sent tos server with ", res.status);
  
        return res.status;
      } catch (error) {
        console.log("the error for unable to send data is ", error);
        return rejectWithValue({ message: error.message, status: error.response?.data });
      }
    }
  );
export const boloRegisteration = createAsyncThunk(
  "fetch/nearby", async (customerData, { rejectWithValue }) => {
    try {
      const data = localStorage.getItem("passUser");
      const token =  JSON.parse(data).accessToken;
      // console.log(token);
      const res = await axios.post("https://mob.tewostechsolutions.com/api/user/register", customerData, {
        headers: {
          "content-type": "application/json",
        //  'Authorization': `Bearer ${token}`
        }
      });
      return res.data;
    } catch (error) {
      return error.code;
    }

  });




const boloSlice = createSlice({
  name: "bolo",
  initialState: {
    isLoading: false,
    data: [],
    isError: false
  },
  reducers: {
    

  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomers.pending, (state, action) => {
      state.isLoading = true;
    })

      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.isLoading = true;
        //   state.data = action.payload;
      
        console.log("added is ", action.payload)
        state.data = action.payload
      })
     
    
      .addCase(boloRegisteration.fulfilled, (state, action) => {
        //state.data.push(action.payload);
        state.addStatus = "success";
      })
      .addCase(boloRegisteration.rejected, (state, action) => {
        state.addStatus = "error";
      })
     
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.isError = true;
      })
      
      .addCase(addCustomers.fulfilled, (state, action) => {
        state.data.push(action.payload);
        state.addStatus = "success";
      })
      .addCase(addCustomers.rejected, (state, action) => {
        state.addStatus = "error";
      })
  }
});

export default boloSlice.reducer;