import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaEnvelope, FaRegBell } from 'react-icons/fa6';


export default function Header() {
    const [sidebarVisible, setSidebarVisible] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setSidebarVisible(true);
            } else {
                setSidebarVisible(false);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const navigate = useNavigate();
    const location = useLocation();
    let currentLink = '';
    const crumbs = location.pathname.split('/').filter(crumb => crumb !== '').map((crumb, index, arr) => {
        currentLink += `/${crumb}`;
        const isLastCrumb = index === arr.length - 1;

        return (
            <div className='crumb' key={index}>
                {isLastCrumb ? (
                    <span className="font-semibold text-gray-900">{crumb}</span>
                ) : (
                    <span
                        className="text-blue-500 cursor-pointer"
                        onClick={() => navigate(currentLink)}
                    >
                        {crumb}
                    </span>
                )}
            </div>
        );
    });

    const data = localStorage.getItem("passUser");
    const avatar = JSON.parse(data).avatar;
    const firstName = JSON.parse(data).first_name;
    const lastName = JSON.parse(data).last_name;
    const role = JSON.parse(data).role;
    const [breadData, setBreadData] = useState('');
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (role === 'Bolo') {
            setBreadData('Pages');
        } else if (role === 'Admin') {
            setBreadData('admin');
        } else {
            setBreadData('user');
        }
    }, [role]);

    const handleLogout = () => {
        localStorage.removeItem('passUser');
        navigate('/');
    };

    const handleProfile = () => {
        if (role === 'Bolo') {
            navigate('/pages/profile');
        } else if (role === 'Admin') {
            navigate('/admin/profile');
        }
    };

    const showDropDown = () => {
        setOpen(!open);
    };

    return (
        <div className="flex items-center justify-between h-16 px-4 bg-white border-b border-neutral-200">
            {sidebarVisible && (
                <nav className="text-sm text-gray-500">
                    <div className="flex space-x-2">
                        {crumbs}
                    </div>
                </nav>
            )}
            {!sidebarVisible && (
                <nav className="text-sm text-gray-500">
                    {/* Empty breadcrumb when sidebar is not visible */}
                </nav>
            )}

            <div className="flex items-center gap-2">
                <div className='relative flex items-center gap-4'>
                    <div className='flex items-center gap-6 pr-6 border-r'>
                        <FaRegBell className="text-gray-600 cursor-pointer" />
                    </div>
                    <div className='relative flex items-center justify-center gap-4' onClick={showDropDown}>
                        <p className='flex items-center justify-center cursor-pointer'>
                            {firstName} {lastName}
                        </p>
                        <div className='relative flex items-center justify-center w-10 h-10 bg-blue-600 rounded-full cursor-pointer'>
                            {/* Avatar or any other content */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
