import React from "react";
import "./menu.css";
import { NavLink } from 'react-router-dom';
import Log from "../../Assets/logo.png";
import  { useRef } from "react";
const Menu = () => {
  const checkboxRef = useRef(null);

  // function to close nav 
  const handleNavClick = () => {
    if (checkboxRef.current) {
      checkboxRef.current.checked = false;
    }
  };
  return (
    <div>
      <section className="NavMenu__section">
        <header className="header">
          <div className="logo">
            <a href="/">
              <img src={Log} alt="" />
            </a>
          </div>
          <div className="navigation">

          <input
              type="checkbox"
              className="toggle-menu"
              ref={checkboxRef} 
            />
            <div className="hamburger"></div>

            <ul className="menu">
              <li>
              <NavLink to="/" onClick={handleNavClick}>Home</NavLink>
              </li>
              <li>
              <NavLink to="/about"onClick={handleNavClick}>About</NavLink>{" "}
              </li>
              <li>
              <NavLink to="/Service"onClick={handleNavClick}>Service</NavLink>
              </li>
              <li>
              <NavLink to="/News"onClick={handleNavClick}>News</NavLink>
              </li>
              <li>
              <NavLink to="/vacancy"onClick={handleNavClick}>Vacancy</NavLink>
              </li>
              <li>
              <NavLink 
  to="/Portfolio" onClick={handleNavClick}
  className="background-link text-white rounded-lg py-2 px-4 hover:bg-blue-600 transition duration-200"
>
  Contact Us
</NavLink>
</li>
             
              {/* <li>
              <NavLink to="/Announcements">Announcements</NavLink>
                { <a href="/Announcements">Announcement</a> }
              </li> */}
              {/* <li>
                <a href="/contact">
                  <button className="menu-contacus-btn">
                    <p class="btn-contact-us">Contact</p>
                  </button>
                </a>
              </li> */}
            </ul>
          </div>
        </header>
      </section>
    </div>
  );
};

export default Menu;
