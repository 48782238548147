import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";

// Import required modules
import { Autoplay, Pagination } from "swiper/modules";

import "../../styles/home/slide.css";

import Log1 from "../../Assets/partner- (1).png";
import Log2 from "../../Assets/partner- (2).png";
import Log3 from "../../Assets/partner- (3).png";
import Log4 from "../../Assets/Awash-Insurance.png";
import Log5 from "../../Assets/partner- (3).png";
import Log6 from "../../Assets/partner- (2).png";
import Log7 from "../../Assets/Hagbes.png"; 
import Log8 from "../../Assets/Abay Insurance.jpg"; 
import Log9 from "../../Assets/Africa Insurance.png"; 
import Log10 from "../../Assets/Anbesa Insurance.jpg"; 
import Log11 from "../../Assets/EIC.jpg"; 

const Slider = () => {
  return (
    <div className="pb-5">
    
      <Swiper
        loop={true}
        autoplay={{
          delay: 1000, // Adjust the delay as needed
          disableOnInteraction: false,
        }}
        slidesPerView={5}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          375: {
            slidesPerView: 1,
          },
          600: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 5,
          },
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
        id="Log__swiper"
      >
        <SwiperSlide>
          <img src={Log1} alt="Partner 1" className="w-full h-auto object-contain"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={Log2} alt="Partner 2" className="w-full h-auto object-contain"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={Log3} alt="Partner 3" className="w-full h-auto object-contain"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={Log4} alt="Partner 4"className="w-full h-auto object-contain" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Log11} alt="Partner 11"className="w-full h-auto object-contain" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Log1} alt="Partner 1" className="w-full h-auto object-contain"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={Log7} alt="Partner 7"className="w-full h-auto object-contain" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Log8} alt="Partner 8"className="w-full h-auto object-contain" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Log9} alt="Partner 9"className="w-full h-auto object-contain" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Log10} alt="Partner 10" className="w-full h-auto object-contain"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={Log5} alt="Partner 5"className="w-full h-auto object-contain" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Log7} alt="Partner 7" className="w-full h-auto object-contain"/>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Slider;

