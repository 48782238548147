import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { FaInfoCircle } from "react-icons/fa";

const DateTimeFormat = (dateString) => {
  const date = new Date(dateString);
  const options = { weekday: 'short' };
  const dayOfWeek = date.toLocaleDateString('en-US', options);
  const monthOptions = { month: 'long' };
  const month = date.toLocaleDateString('en-US', monthOptions);
  const year = date.getFullYear();
  return `${dayOfWeek}, ${month} ${year}`;
};

const NewList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const fetchUsers = async () => {
    try{
      const data = localStorage.getItem("passUser");
       const token = JSON.parse(data).accessToken;
     // console.log(token);
      const res = await axios.get("https://api.tewostech.com/api/news", {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
       console.log(res.data.data);
      setUsers(res.data.data);
    } catch (error) {
      toast.error('An error occurred!', {
        position: 'top-right'
      });
    }
   
  };

  useEffect(() => {
    fetchUsers();
  }, [dispatch]);

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    if (searchTerm === "") {
      setUsersPerPage(10); 
    }
    setSearchTerm(searchTerm);
  };
  
  useEffect(() => {
    if (searchTerm !== "") {
      const filteredUsers = users.filter(
        (user) =>
        user?.headline?.toLowerCase().includes(searchTerm) ||
        user?.content?.toLowerCase().includes(searchTerm) ||
        user?.publication_date?.toLowerCase().includes(searchTerm) 
      );
      setUsers(filteredUsers);
      setUsersPerPage(filteredUsers.length); // Set the pagination based on the filtered results
    } else {
      fetchUsers();
    }
  }, [searchTerm]);


  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleDetailNews = (newsItem) => {
    const newsId = newsItem.id; // Assuming the news item has an `id`
    navigate(`/admin/new-detail/${newsId}`);
  };

  const handleUpdateNews = (newsItem) => {
    navigate(`/admin/news-update/${newsItem.id}`);
  }

  const handleDeleteNews = async (newsItem) => {
    const userConfirmation = window.confirm("Are you sure you want to delete this news?");
    if (userConfirmation) {
      try {
        const data = localStorage.getItem("passUser");
        const token = JSON.parse(data).accessToken;
        await axios.delete(`https://api.tewostech.com/api/news/${newsItem.id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        toast.success('News deleted successfully!', {
          position: 'top-right'
        });
        fetchUsers(); // Refresh the list after deletion
      } catch (error) {
        toast.error('An error occurred while deleting the news!', {
          position: 'top-right'
        });
      }
    }
  }
  
  const indexOfLastUser = (currentPage + 1) * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <div class="grid">
      <h3 className='mb-3 text-secondary'>New List</h3>
      <div class="bg-white relative shadow-md sm:rounded-lg overflow-hidden">
        <div class="grid  grid-cols-1 sm:grid-cols-2 md:gap-4 justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
        <div class="relative w-full">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
              </svg>
            </div>
            <input
              type="text"
              class="bg-gray-50 border mx-2 px-4 border-gray-300 text-black text-sm rounded-lg   block w-full pl-10 p-2 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search" required=""
              aria-label="filterbox"
              aria-describedby="basic-addon1"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>

          <div class="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-end md:items-center justify-end md:space-x-3 flex-shrink-0">
          </div>

        </div>
        <div class="overflow-x-auto">
          <table className="table caption-top table-striped border-1 table-hover table-bordered">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Title</th>
                {/* <th scope="col">Create Date</th> */}
                <th scope="col">Publish Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.length === 0 ? (
                <tr>
                  <td className="text-center" colSpan="4">No data</td>
                </tr>
              ) : (
                currentUsers.map((location) => (
                    <tr key={location.id}>
                      <td className="px-2 py-2 border-b">{location.id}</td>
                      <td className="px-2 py-2 border-b">{location.headline}</td>
                      {/* <td className="px-4 py-2 border-b">{location.created_at}</td> */}
                      <td className="px-2 py-2 border-b">{DateTimeFormat(location.publication_date)}</td>
                      <td className="px-2 py-2 border-b">
                      <div className="flex gap-2">
                      <button className="text-blue-600" onClick={(event) => handleUpdateNews(location)}><FaEdit /></button>
                      <button className="text-yellow-600" onClick={(event) => handleDetailNews(location)}><FaInfoCircle/></button>
                      <button className="text-red-500" onClick={(event) => handleDeleteNews(location)}><MdDelete/></button>
                      </div>
                      {/* </div> */}
                    </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>
          <ReactPaginate
            breakLabel=""
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={Math.ceil(users.length / usersPerPage)}
            previousLabel="< Previous"
            renderOnZeroPageCount={null}
            containerClassName="pagination justify-content-center"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
          />
        </div>

      </div>
    </div>
  );
};

export default NewList;