import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "../api/accountSlice";
import boloManagementReducer from "../api/bolo/boloSlice";
// import sharedDataStore from "../api/sharedData";


export const store = configureStore({
    reducer: {
        bolo:boloManagementReducer,
        account: accountReducer,
        // sharedDataReducer:sharedDataStore
    }
});


