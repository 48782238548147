import React, { useState, useEffect } from "react";
import jsPDF from 'jspdf';
import axios from "axios";
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminMonthlySMSReport = () => {
  const [customersPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const data = localStorage.getItem("passUser");
  const token = JSON.parse(data).accessToken;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCustomers, setFilteredCustomers] = useState([]);

  //pagination
  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    fetchCustomers(event.selected + 1);
  };

  const fetchCustomers = async (currentPage) => {
    try {
      const response = await axios.get(`https://mob.tewostechsolutions.com/api/monthlyadminsms?page=${currentPage}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });
      setCustomers(response.data.monthly_sms_count.data);
      setTotalPages(Math.ceil(response.data.monthly_sms_count.total / 10)); 
      setIsLoading(true);
    } catch (error) {
      setIsLoading(false);
      toast.error('An error occurred!', {
        position: 'top-right'
      });
    }
  };

  useEffect(() => {
    fetchCustomers(currentPage);
  }, []);

  useEffect(() => {
    const filteredData = customers.filter((customer) => {
      const customerName = customer.customer_name.toLowerCase();
      const plateNumber = customer.plate_number.toLowerCase();
      const phoneNumber = customer.phone_number.toLowerCase();
      const inspectDate = customer.inspect_date.toLowerCase();
      // const daysRemaining = customer.days_remaining.toString().toLowerCase();

      return (
        customerName.includes(searchTerm) ||
        plateNumber.includes(searchTerm) ||
        phoneNumber.includes(searchTerm) ||
        inspectDate.includes(searchTerm) 
        // daysRemaining.includes(searchTerm)
      );
    });

    setFilteredCustomers(filteredData);
    setCurrentPage(0); // Reset to the first page when filtering changes
  }, [customers, searchTerm]);

  const generatePdfData = () => {
    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.text('Customer Information', 20, 20);
    let y = 35;
    doc.setFontSize(12);
    doc.text('Customer Name', 20, y);
    doc.text('Plate Number', 70, y);
    doc.text('Phone Number', 120, y);
    doc.text('Inspection Date', 170, y);
    y += 10;
    currentUsers.forEach((element) => {
      doc.text(element.customer_name, 20, y);
      doc.text(element.plate_number, 70, y);
      doc.text(element.phone_number, 120, y);
      doc.text(element.inspect_date, 170, y);
      y += 10;
      if (y + 10 > doc.internal.pageSize.getHeight() - 20) {
        doc.addPage();
        y = 20;
      }
    });
    doc.save('Monthly Customer Information.pdf');
  };

  const indexOfLastUser = (currentPage + 1) * customersPerPage;
  const indexOfFirstUser = indexOfLastUser - customersPerPage;
  const currentUsers = filteredCustomers.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <div className="grid">
      <h3 className="mb-3 text-secondary">Monthly Inspection Center List</h3>
      <div className="flex justify-center row">
         <div class="grid  grid-cols-1 sm:grid-cols-2 md:gap-4 justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
           <div class="relative w-full">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
              </svg>
            </div>
            <input
              type="text"
              class="bg-gray-50 border mx-2 px-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search" required=""
              aria-label="filterbox"
              aria-describedby="basic-addon1"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
            />
          </div>
          <div class="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-end md:items-center justify-end md:space-x-3 flex-shrink-0">
            <button className="btn btn-primary" onClick={generatePdfData}>
            Generate Pdf
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto">
        <table className="table caption-top table-striped border-1 table-hover ">
          <thead>
           <tr>
              <th scope="col">Customer Name</th>
              <th scope="col">Plate Number</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Inspection Date</th>
              <th scope="col">Days Remaining</th>
              <th scope="col">Company Name</th>
            </tr>
          </thead>
          <tbody>
            {currentUsers.length === 0 ? (
                <tr>
                  <td class="px-4 py-2 text-center border-b" colSpan="6">
                  {isLoading ? 'Loading...' : 'No data'}
                  </td>
                </tr>
              ) :currentUsers.map((customer) => (
              <tr key={customer.id}>
                <td>{customer.customer_name}</td>
                <td>{customer.plate_number}</td>
                <td>{customer.phone_number}</td>
                <td>{customer.inspect_date}</td>
                <td>{customer.remaining_days}</td>
                <td>{customer.company_name} </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {totalPages > 1 && (
        <ReactPaginate
          breakLabel=""
          nextLabel="Next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={totalPages}
          previousLabel="< Previous"
          renderOnZeroPageCount={null}
          containerClassName="pagination justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active"
        />
      )}
    </div>
  );
};

export default AdminMonthlySMSReport;