import React, { useState } from 'react';
import logo from '../../src/assest/logo.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { HiOutlineLogout } from 'react-icons/hi';
import { TiNews } from "react-icons/ti";
import { MdOutlinePostAdd } from "react-icons/md";
import { AiOutlineDashboard } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { IoMdAdd } from "react-icons/io";
import { IoListOutline } from "react-icons/io5";

const linkClasses =
    'flex items-center gap-2 font-light px-3 py-2 no-underline hover:bg-neutral-200 hover:no-underline active:bg-neutral-200 rounded-sm text-base';

const AdminSidebar = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [isNewsOpen, setIsNewsOpen] = useState(false);
    const [isNewsOpenVaccany, setIsNewsOpenVaccany] = useState(false);

    const adminData = {
        data: "admin"
    };

    const handleLogout = () => {
        localStorage.removeItem('passUser');
        navigate('/login');
    };

    const toggleNewsMenu = () => {
        setIsNewsOpen(!isNewsOpen);
    };

    const toggleNewsMenuVaccany = () => {
        setIsNewsOpenVaccany(!isNewsOpenVaccany);
    };

    return (
        <div className='flex flex-col h-full p-3 text-black bg-neutral-100 w-60'>
            <div className='flex items-center gap-2 px-1 py-3'>
                <img src={logo} width="105px" height="56px" alt="Yene Mekina" />
            </div>
            <div className="py-8 flex flex-1 flex-col gap-0.5">
                <Link to={{
                    pathname: '/admin',
                    state: { data: adminData }
                }}
                    className={classNames(pathname === '/admin' ? 'bg-white text-gray-dark' : 'text-neutral-400', linkClasses)}>
                    <span className='text-xl'>
                        <AiOutlineDashboard />
                    </span>
                    Dashboard
                </Link>

                <div className="flex flex-col">
                    <button onClick={toggleNewsMenu} className={classNames((pathname.startsWith('/admin/news') || pathname.startsWith('/admin/add-news')) ? 'bg-white text-gray-dark' : 'text-neutral-400', linkClasses)}>
                        <span className='text-xl'>
                            <TiNews />
                        </span>
                        News
                        <span className='ml-auto'>
                            {isNewsOpen ? <IoIosArrowDown /> : <IoIosArrowForward />}
                        </span>
                    </button>

                    {isNewsOpen && (
                        <div className="ml-8 mt-2 flex flex-col gap-0.6">
                            <Link to='/admin/news' className={classNames(pathname === '/admin/news' ? 'bg-white text-gray-dark' : 'text-neutral-400', linkClasses)}>
                            <IoListOutline />  All News
                            </Link>
                            <Link to='/admin/add-news' className={classNames(pathname === '/admin/add-news' ? 'bg-white text-gray-dark' : 'text-neutral-400', linkClasses)}>
                            <IoMdAdd /> Add News
                            </Link>
                        </div>
                    )}
                </div>

                <div className="flex flex-col">
                    <button onClick={toggleNewsMenuVaccany} className={classNames((pathname.startsWith('/admin/vaccancy') || pathname.startsWith('/admin/add-vaccancy')) ? 'bg-white text-gray-dark' : 'text-neutral-400', linkClasses)}>
                        <span className='text-xl'>
                            <TiNews />
                        </span>
                        Vaccancy
                        <span className='ml-auto'>
                            {isNewsOpenVaccany ? <IoIosArrowDown /> : <IoIosArrowForward />}
                        </span>
                    </button>

                    {isNewsOpenVaccany && (
                        <div className="ml-8 mt-2 flex flex-col gap-0.5">
                            <Link to='/admin/vaccancy' className={classNames(pathname === '/admin/vaccancy' ? 'bg-white text-gray-dark' : 'text-neutral-400', linkClasses)}>
                            <IoListOutline />   All Vaccancy
                            </Link>
                            <Link to='/admin/add-vaccancy' className={classNames(pathname === '/admin/add-vaccancy' ? 'bg-white text-gray-dark' : 'text-neutral-400', linkClasses)}>
                            <IoMdAdd /> Add Vaccancy
                            </Link>
                        </div>
                    )}
                </div>
            </div>

            <div className=' flex flex-col gap-0.5 pt-2 border-t border-neutral-200'>
                <button onClick={handleLogout}
                    className={classNames(linkClasses, 'cursor-pointer text-red-500')}
                >
                    <span className="text-xl">
                        <HiOutlineLogout />
                    </span>
                    Logout
                </button>
            </div>
        </div>
    );
}

export default AdminSidebar;
