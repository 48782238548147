import React, { useEffect, useState } from "react";
import { getAllUsers } from "../../api/bolo/boloSlice";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { IoIosArrowBack } from "react-icons/io";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UpdateNews() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [images, setImages] = useState(null);
  const [formData, setFormData] = useState({
    headline: "",
    source: "",
    content: "",
    url: "",
    publication_date: "",
    category_id: "",
    user_id: "",
    created_at: "",
    updated_at: "",
    media: "",
  });

  const fetchNewsItem = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("passUser")).accessToken;
      const res = await axios.get(`https://api.tewostech.com/api/news/${id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const newsData = res.data.data;
      console.log(newsData.publication_date);
      setFormData({
        headline: newsData.headline || "",
        source: newsData.source || "",
        content: newsData.content || "",
        url: newsData.url || "",
        publication_date: newsData.publication_date || "",
        category_id: newsData.category_id || "",
        user_id: newsData.user_id || "",
        created_at: newsData.created_at || "",
        updated_at: newsData.updated_at || "",
        media: newsData.media || "",
      });
    } catch (error) {
      toast.error("An error occurred while fetching the news item!", {
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    fetchNewsItem();
  }, [dispatch]);

  const handleFileUpload = (e) => {
    setImages(e.target.files[0]);
  };

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataContainer = {
        ...formData,
        medias: images,
      };

      const token = JSON.parse(localStorage.getItem("passUser")).accessToken;
      console.log(token)
      const res = await axios.put(
        `https://api.tewostech.com/api/news/${id}`,
        dataContainer,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.data) {
        toast.success("Updated Successfully!", {
          position: "top-right",
        });
        navigate("/admin/news");
        // dispatch(getAllUsers());
      }
    } catch (error) {
      toast.error("An error occurred during the update!", {
        position: "top-right",
      });
    }
  };

  const handleBack = () => {
    navigate("/admin/news");
  };

  return (
    <div className="m-4">
      <div className="w-full mx-auto bg-white rounded-lg">
        <div className="flex items-center px-2 py-2 border-b border-gray-200">
        <button onClick={(event) => handleBack()}>
            <IoIosArrowBack size={35} />
          </button>
          <h2 className="ml-4 text-xl font-semibold">Update News</h2>
        </div>
        <div className="p-4">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-4">
              <div>
                <label className="block text-gray-700" htmlFor="headline">
                  Title
                </label>
                <input
                  id="headline"
                  type="text"
                  placeholder="Enter your title"
                  name="headline"
                  value={formData.headline}
                  onChange={handleInputChange}
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 mt-4">
              <div>
                <label className="block text-gray-700" htmlFor="content">
                  Content
                </label>
                <textarea
                  id="content"
                  placeholder="Enter Contents"
                  name="content"
                  value={formData.content}
                  onChange={handleInputChange}
                  required
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <label className="block text-gray-700" htmlFor="source">
                  Source
                </label>
                <input
                  id="source"
                  type="text"
                  placeholder="Enter source"
                  name="source"
                  value={formData.source}
                  onChange={handleInputChange}
                  required
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-gray-700" htmlFor="url">
                  URL
                </label>
                <input
                  id="url"
                  type="text"
                  placeholder="Enter URL"
                  name="url"
                  value={formData.url}
                  onChange={handleInputChange}
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <label className="block text-gray-700" htmlFor="category_id">
                  Category
                </label>
                <select
                  id="category_id"
                  name="category_id"
                  value={formData.category_id}
                  onChange={handleInputChange}
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select Category</option>
                  <option value="1">Transport</option>
                  <option value="2">Yene Mekina</option>
                  <option value="3">Bolo</option>
                </select>
              </div>
              <div>
                <label className="block text-gray-700" htmlFor="publication_date">
                  Publication Date
                </label>
                <input
                  id="publication_date"
                  type="date"
                  placeholder="Insert publication date"
                  name="publication_date"
                  value={formData.publication_date}
                  onChange={handleInputChange}
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div className="mt-4">
              <label className="block text-gray-700" htmlFor="medias">
                Content Images
              </label>
              <input
                id="media"
                type="file"
                onChange={handleFileUpload}
                className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="flex justify-end gap-4 p-4 mt-4 border-t border-gray-200">
              <button
                type="submit"
                className="px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700"
              >
                Update
              </button>
              {/* <button
                type="button"
                onClick={() => navigate("/admin/news")}
                className="px-4 py-2 text-white bg-red-600 rounded-md hover:bg-red-700"
              >
                Cancel
              </button> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UpdateNews;
