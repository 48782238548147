import React from "react";
import "../../styles/about/about.css";
import AboutBg from "../../Assets/About-bg.png";
import Right from "../../Assets/Right.png";
import SecondAbout from "../../Assets/about2.png";
import Fade from "react-reveal/Fade";

const AboutMain = () => {
  return (
    <div>
      <section>
        <div
          className="About__Banner"
          style={{
            backgroundImage: `url(${AboutBg})`,
          }}
        >
          <Fade bottom duration={1500}>
            <div className="About__Container">
              <div className="First ">
                {/* <h2>Creative Services For Boost Your Business Growth</h2> */}
                {/* <h2>LET US TELL YOU WHO WE ARE & WHAT WE DO</h2> */}
                <h2>Let Us tell you who we are & what we Do</h2>
              </div>
            </div>
          </Fade>
        </div>
        {/* ================Main__Content================== */}

        <div className="Abouts__Text mb-5">
          About <span className="Abouts__span">Us</span>
        </div>
        <div className="Main__About pt-5">
          <Fade bottom duration={1500}>
            <div>
              <div className="">
                <p className="pt-10">
                  {" "}
                  VISION
                </p>
                <h3>
                  To add value to the Ethiopian people by providing easy,
                  valuable Information Technology Solutions which will solve
                  local problems and create growing value for the Ethiopian
                  economy using a combination of talent & technology to develop
                  cost-effective solutions.
                </h3>
              </div>
              <div>
                <p>
                  {" "}
                  MISSION
                </p>
                <h3 >
                  To enhance the business operations of our clients by delivering 
                  premium IT products and services. We strive to provide effective, 
                  efficient, and secure solutions tailored to the Ethiopian environment 
                  while supporting the country's needs. Our goal is to become a high-impact 
                  provider of IT services in Africa and to offer our employees meaningful 
                  work and growth opportunities.
                </h3>
              </div>
            </div>
            <div className="flex-1 mt-2 md:mt-0">
              <img 
                src={SecondAbout} 
                alt=""  
                className="w-full h-auto max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl -mt-10" // Add negative margin here
              />
            </div>
          </Fade>
        </div>
      </section>
    </div>
  );
};

export default AboutMain;

