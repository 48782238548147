import React, { useState, useEffect } from "react";
import { getAllUsers } from "../../api/bolo/boloSlice";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosArrowBack } from "react-icons/io";

function UpdateVaccany() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    job_title: "",
    job_description: "",
    location: "",
    department: "",
    employment_type: "",
    salary_min: "",
    salary_max: "",
    qualifications: "",
    responsibilities: "",
    benefits: "",
    application_deadline: "",
    how_to_apply: "",
    contact_email: "",
    company_overview: "",
    equal_opportunity_statement: "",
  });

  const fetchUsers = async () => {
    try {
      const data = localStorage.getItem("passUser");
      const token = JSON.parse(data).accessToken;
      const res = await axios.get(
        `https://api.tewostech.com/api/vacancy/${id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(res.data);
      const newsData = res.data;
      setFormData({
        job_title: newsData.job_title || "",
        job_description: newsData.job_description || "",
        location: newsData.location || "",
        department: newsData.department || "",
        employment_type: newsData.employment_type || "",
        salary_min: newsData.salary_min || "",
        salary_max: newsData.salary_max || "",
        qualifications: newsData.qualifications || "",
        responsibilities: newsData.responsibilities || "",
        benefits: newsData.benefits || "",
        application_deadline: newsData.application_deadline || "",
        how_to_apply: newsData.how_to_apply || "",
        contact_email: newsData.contact_email || "",
        company_overview: newsData.company_overview || "",
        equal_opportunity_statement: newsData.equal_opportunity_statement || "",
      });
    } catch (error) {
      toast.error("An error occurred!", {
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [dispatch]);

  const handleBack = () => {
    navigate("/admin/vaccancy");
  };

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataContainer = {
        job_title: formData.job_title,
        job_description: formData.job_description,
        location: formData.location,
        department: formData.department,
        employment_type: formData.employment_type,
        salary_min: formData.salary_min,
        salary_max: formData.salary_max,
        qualifications: formData.qualifications,
        responsibilities: formData.responsibilities,
        benefits: formData.benefits,
        application_deadline: formData.application_deadline,
        how_to_apply: formData.how_to_apply,
        contact_email: formData.contact_email,
        company_overview: formData.company_overview,
        equal_opportunity_statement: formData.equal_opportunity_statement,
      };
      const form = e.currentTarget;
      if (form.checkValidity()) {
        try {
          const data = localStorage.getItem("passUser");
          const token = JSON.parse(data).accessToken;
          // console.log(dataContainer);
          const res = await axios.put(
            `https://api.tewostech.com/api/vacancy/${id}`,
            dataContainer,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (res.data) {
            toast.success("Registered Successfully!", {
              position: "top-right",
            });
            navigate("/admin/vaccancy");
          }
        } catch (error) {
          return error.code;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="m-4">
      <div className="w-full mx-auto bg-white rounded-lg">
        <div className="flex items-center px-2 py-2 border-b border-gray-200">
        <button onClick={(event) => handleBack()}>
            <IoIosArrowBack size={35} />
          </button>
          <h2 className="ml-4 text-xl font-semibold">Update Vaccancy</h2>
        </div>
        <div className="p-4">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-4">
              <div>
                <label className="block text-gray-700" htmlFor="formBasicName">
                  Job Title
                </label>
                <input
                  id="formBasicName"
                  type="text"
                  placeholder="Enter Job Title"
                  name="job_title"
                  value={formData.job_title}
                  onChange={handleInputChange}
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 mt-4">
              <div>
                <label className="block text-gray-700" htmlFor="formBasicEmail">
                  Job Description
                </label>
                <textarea
                  id="formBasicEmail"
                  type="text"
                  placeholder="Enter Job Description"
                  name="job_description"
                  value={formData.job_description}
                  onChange={handleInputChange}
                  required
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <label
                  className="block text-gray-700"
                  htmlFor="formBasicPassword"
                >
                  Location
                </label>
                <input
                  id="formBasicPassword"
                  type="text"
                  placeholder="Enter location"
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  required
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label
                  className="block text-gray-700"
                  htmlFor="formBasicPassword"
                >
                  Department
                </label>
                <input
                  id="formBasicPassword"
                  type="text"
                  placeholder="Enter department"
                  name="department"
                  value={formData.department}
                  onChange={handleInputChange}
                  required
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4 mt-4">
              <div>
                <label
                  className="block text-gray-700"
                  htmlFor="formBasicPassword"
                >
                  Employment type
                </label>
                <input
                  id="formBasicPassword"
                  type="text"
                  placeholder="Select employment type"
                  name="employment_type"
                  value={formData.employment_type}
                  onChange={handleInputChange}
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label
                  className="block text-gray-700"
                  htmlFor="formBasicPassword"
                >
                  Minimun Salary
                </label>
                <input
                  id="formBasicPassword"
                  type="number"
                  placeholder=" Minimum Salary"
                  name="salary_min"
                  value={formData.salary_min}
                  onChange={handleInputChange}
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label
                  className="block text-gray-700"
                  htmlFor="formBasicPassword"
                >
                  Maximum Salary
                </label>
                <input
                  id="formBasicPassword"
                  type="number"
                  placeholder="Maximum Salary"
                  name="salary_max"
                  value={formData.salary_max}
                  onChange={handleInputChange}
                  required
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 mt-4">
              <div>
                <label
                  className="block text-gray-700"
                  htmlFor="formBasicPassword"
                >
                  Qualifications
                </label>
                <input
                  id="qualifications"
                  type="text"
                  placeholder="Insert qualifications"
                  name="qualifications"
                  value={formData.qualifications}
                  onChange={handleInputChange}
                  required
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 mt-4">
              <div>
                <label
                  className="block text-gray-700"
                  htmlFor="formBasicRoletype"
                >
                  Responsibilities
                </label>
                <textarea
                  id="responsibilities"
                  name="responsibilities"
                  type="text"
                  value={formData.responsibilities}
                  placeholder="enter responsibilities"
                  onChange={handleInputChange}
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <label className="block text-gray-700" htmlFor="contact_email">
                  Contact email
                </label>
                <input
                  id="formBasicPassword"
                  type="text"
                  placeholder="enter contact email"
                  name="contact_email"
                  value={formData.contact_email}
                  onChange={handleInputChange}
                  required
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label
                  className="block text-gray-700"
                  htmlFor="application_deadline"
                >
                  Deadline
                </label>
                <input
                  id="application_deadline"
                  type="date"
                  placeholder="enter deedline"
                  name="application_deadline"
                  value={formData.application_deadline}
                  onChange={handleInputChange}
                  required
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4 mt-4">
              <div>
                <label className="block text-gray-700" htmlFor="benefits">
                  Benefit
                </label>
                <textarea
                  id="benefits"
                  name="benefits"
                  type="text"
                  value={formData.benefits}
                  placeholder="enter benefits"
                  onChange={handleInputChange}
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 mt-4">
              <div>
                <label
                  className="block text-gray-700"
                  htmlFor="formBasic_how_to_apply"
                >
                  How to apply
                </label>
                <textarea
                  id="how_to_apply"
                  name="how_to_apply"
                  type="text"
                  value={formData.how_to_apply}
                  placeholder="how to apply"
                  onChange={handleInputChange}
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 mt-4">
              <div>
                <label
                  className="block text-gray-700"
                  htmlFor="formBasicCompanyOverview"
                >
                  Company overview
                </label>
                <textarea
                  id="company_overview"
                  name="company_overview"
                  type="text"
                  value={formData.company_overview}
                  placeholder="enter company overview"
                  onChange={handleInputChange}
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 mt-4">
              <div>
                <label
                  className="block text-gray-700"
                  htmlFor="formBasicEqualOpportunity"
                >
                  Equal Opportunity Statement
                </label>
                <textarea
                  id="equal_opportunity_statement"
                  name="equal_opportunity_statement"
                  type="text"
                  value={formData.equal_opportunity_statement}
                  placeholder="enter equal opportunity"
                  onChange={handleInputChange}
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div className="grid justify-end grid-cols-2 gap-4 p-4 mt-4 border-t border-gray-200 ">
              <button
                type="submit"
                className="py-2 m-4 text-white bg-blue-600 rounded-md hover:bg-blue-700"
              >
                Update
              </button>
              {/* <button
                type="submit"
                className="py-2 m-4 text-white bg-red-600 rounded-md hover:bg-red-700"
              >
                Close
              </button> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UpdateVaccany;
