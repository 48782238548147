
import React from "react";
import "../../styles/home/homeport.css";
import PortOne from "../../Assets/etbolo.png";
import PortOnee from "../../Assets/teamm2.jpg";
import PortTwo from "../../Assets/teamm.jpg";
import PortThree from "../../Assets/yene mekina.png";
import PortFour from "../../Assets/etbolomob.png";
import PortFive from "../../Assets/teamm3.jpg";
import PortBg from "../../Assets/port-services.png";
import IconButton from "../../Assets/Plus.png";
import Fade from "react-reveal/Fade";

const HomePort = () => {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <section>
        <div
          style={{
            backgroundImage: `url(${PortBg})`,
          }}
          className="bg-cover bg-center py-10"
        >
          <div className="text-center text-3xl md:text-4xl font-bold Ports__Text">
            Our <span className="Ports__span">Portfolio</span>
          </div>
          <div className="Port__Main__Header flex flex-col md:flex-row items-center justify-between mt-6">
            <h4 className="text-lg md:text-xl Homeport__header text-center md:text-left">
              Our client, global analytical techno company, wanted to build
              market.
            </h4>
            <button className="mt-4 md:mt-0">
              <p>
                <a className="no-underline text-blue-500 hover:underline" href="/portfolio">More Gallery</a>
              </p>
            </button>
          </div>
          <Fade bottom duration={1500}>
            <div className="Homework__container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-10">
              <div className="container">
                <figure className="hover-img">
                  <img src={PortOne} className="w-full h-auto" />
                  <figcaption className="mt-4 text-center">
                    <a href="/portfolio">
                      <img className="Icon__img" src={IconButton} alt="" />
                    </a>
                    <h2 className="text-lg font-medium Sub__text">
                      Tewos Vehicle Inspection Information System
                    </h2>
                  </figcaption>
                </figure>
              </div>
              <div className="container">
                <figure className="hover-img">
                  <img src={PortOnee} className="w-full h-auto" />
                  <figcaption className="mt-4 text-center">
                    <a href="/portfolio">
                      <img className="Icon__img" src={IconButton} alt="" />
                    </a>
                    <h2 className="text-lg font-medium Sub__text">
                      DRIVING SCHOOL MANAGEMENT SYSTEM
                    </h2>
                  </figcaption>
                </figure>
              </div>
              <div className="container">
                <figure className="hover-img">
                  <img src={PortTwo} className="w-full h-auto" />
                  <figcaption className="mt-4 text-center">
                    <a href="/portfolio">
                      <img className="Icon__img" src={IconButton} alt="" />
                    </a>
                    <h2 className="text-lg font-medium Sub__text">
                      TECHNOLOGY PROVISION AND IT CONSULTING
                    </h2>
                  </figcaption>
                </figure>
              </div>
              <div className="container">
                <figure className="hover-img">
                  <img src={PortThree} className="w-full h-auto" />
                  <figcaption className="mt-4 text-center">
                    <a href="/portfolio">
                      <img className="Icon__img" src={IconButton} alt="" />
                    </a>
                    <h2 className="text-lg font-medium Sub__text">
                      YENE MEKINA SYSTEM
                    </h2>
                  </figcaption>
                </figure>
              </div>
              <div className="container">
                <figure className="hover-img">
                  <img src={PortFour} className="w-full h-auto" />
                  <figcaption className="mt-4 text-center">
                    <a href="/portfolio">
                      <img className="Icon__img" src={IconButton} alt="" />
                    </a>
                    <h2 className="text-lg font-medium Sub__text">
                      CUSTOM APPLICATION DEVELOPMENT SERVICES
                    </h2>
                  </figcaption>
                </figure>
              </div>
              <div className="container">
                <figure className="hover-img">
                  <img src={PortFive} className="w-full h-auto" />
                  <figcaption className="mt-4 text-center">
                    <a href="/portfolio">
                      <img className="Icon__img" src={IconButton} alt="" />
                    </a>
                    <h2 className="text-lg font-medium Sub__text">
                      YENE DELIVERY SYSTEM
                    </h2>
                  </figcaption>
                </figure>
              </div>
            </div>
          </Fade>
        </div>

        <Fade bottom duration={1500}>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-40 ">
            <div className="no1">
              <div className="Sup flex items-center justify-center text-xl md:text-2xl">
                13<sup>+</sup>
              </div>
              <p className="lorem  text-sm md:text-base mt-2">Software Developments</p>
            </div>
            <div className="no2">
              <div className="Sup flex items-center justify-center text-xl md:text-2xl">
                110
              </div>
              <p className="lorem  text-sm md:text-base mt-2">Application Management</p>
            </div>
            <div className="no3">
              <div className="Sup flex items-center justify-center text-xl md:text-2xl">
                99<sup>%</sup>
              </div>
              <p className="lorem  text-sm md:text-base mt-2">Quality Assurance</p>
            </div>
            <div className="no4">
              <div className="Sup flex items-center justify-center text-xl md:text-2xl">
                110<sup>+</sup>
              </div>
              <p className="lorem  text-sm md:text-base mt-2">Application Development</p>
            </div>
          </div>
        </Fade>
      </section>
    </div>
  );
};

export default HomePort;


