import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const VacancyDetail = () => {
    const location = useLocation();
    const vacancy = location.state?.vacancy;

    useEffect(() => {
        if (!vacancy) {
            toast.error('No vacancy details found!', {
                position: 'top-right'
            });
        }
    }, [vacancy]);

    if (!vacancy) return <div>Loading...</div>;

    return (
        <div className="container mx-auto px-4 py-8">
            <section className="pt-5">
                <div className="  mt-5 flex flex-col md:flex-row bg-white border rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                    <div className="flex-1 p-4">
                        <h5 className="font-bold text-center">{vacancy.job_title}</h5>
                        <h6 className="pt-4">
                            <strong className="text-sm">Department:</strong>
                            <span className="text-sm text-gray-600"> {vacancy.department}</span>
                        </h6>
                        <h6 className="pt-4">
                            <strong className="text-sm">Location:</strong>
                            <span className="text-sm text-gray-600"> {vacancy.location}</span>
                        </h6>
                        <h6 className="pt-4">
                            <strong className="text-sm">Employment Type:</strong>
                            <span className="text-sm text-gray-600"> {vacancy.employment_type}</span>
                        </h6>
                    </div>
                    <div className="border-l border-gray-300 md:h-auto"></div>
                    <div className=" text-left flex-1 p-4">
                        <h6 >
                            <strong className="text-sm ">Description:</strong>
                            <span className="text-sm text-gray-600"> {vacancy.job_description}</span>
                        </h6>
                        <h6 className="pt-4">
                            <strong className="text-sm">Qualifications:</strong>
                            <span className="text-sm text-gray-600"> {vacancy.qualifications}</span>
                        </h6>
                        <h6 className="pt-4">
                            <strong className="text-sm">Benefits:</strong>
                            <span className="text-sm text-gray-600"> {vacancy.benefits}</span>
                        </h6>
                        <h6 className="pt-4">
                            <strong className="text-sm">Responsibilities:</strong>
                            <span className="text-sm text-gray-600"> {vacancy.responsibilities}</span>
                        </h6>
                        <h6 className="pt-4">
                            <strong className="text-sm">Salary From:</strong>
                            <span className="text-sm text-gray-600"> {vacancy.salary_min} - {vacancy.salary_max} ETB</span>
                        </h6>
                        <p className="pt-4">
                            <strong className="text-sm">Application Deadline : </strong>
                            <span className="text-sm text-red-600">{new Date(vacancy.application_deadline).toLocaleDateString()}</span>
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default VacancyDetail;