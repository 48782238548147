import React from "react";
import Log from "../../Assets/logo.png";
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <div className="h-auto flex flex-col">
      {/* Main Content */}
      <div className="flex-grow">
       
      </div>

      <div className="grid mt-6" style={{ backgroundImage: 'linear-gradient(112.68deg, #F16823 0%, #EC1276 100%)' }}>
        <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 p-4">
          <div className="rounded-md">
            <div>
              <img className="w-1/4" src={Log} alt="Logo" />
              <p className="text-white mt-2">
                Working Hour <br /> Monday - Friday - from 8:30 am – 5:30 pm
                <br /> Saturday - from 8:30 am – 5:30 pm
              </p>
            </div>
          </div>
          <div className="text-white">
            <h2>Pages</h2>
            <ul className="list-disc list-outside ml-4">
              <li><a className="text-white no-underline" href="/">Home</a></li>
              <li><a className="text-white no-underline" href="/about">About</a></li>
              <li><a className="text-white no-underline" href="/Service">Service</a></li>
              <li><a className="text-white no-underline" href="/Portfolio">Portfolio</a></li>
            </ul>
          </div>
          <div className="text-white">
            <h2>Contact Us</h2>
            <ul className="list-disc list-outside">
              <li><a className="text-white no-underline" href="/">+251911516413</a></li>
              <li><a className="text-white no-underline" href="/about">+251911515291</a></li>
              <li><a className="text-white no-underline" href="/Service">info@tewostech.com</a></li>
              <li><a className="text-white no-underline" href="/Portfolio">support@tewostech.com</a></li>
            </ul>
          </div>
          <div className="text-black">
            <h2 className="text-white">Newsletter</h2>
            <input className="Footer__input border border-gray-300 rounded-md p-2 w-full" type="email" placeholder="enter your mail..." />
            <button className="bg-white text-black rounded-2xl mt-2 px-4">Submit</button>

            <ul className="flex justify-start space-x-4 mt-4">
              <li><a href='https://www.facebook.com/profile.php?id=100092359218655'><FaFacebook className="text-white" /></a></li>
              <li><a href='https://www.instagram.com/tewos_technology/'><FaInstagram className='text-white' /></a></li>
              <li><a href='https://www.linkedin.com/company/tewos-technology/'><FaLinkedin className='text-white' /></a></li>
              <li><a href='#'><FaTwitter className='text-white' /></a></li>
            </ul>
          </div>
        </div>

        <div className="flex items-center justify-center bg-[#2c3e50] h-[50px]">
          <p className="text-white m-0">©2023</p>
          <span className="text-white mx-1">
            <a className="no-underline bg-gradient-to-r from-[#f05f2b] to-[#ec1771] bg-clip-text text-transparent" href="https://Tewostech.com/">
              Tewostech
            </a>
          </span>
          <p className="text-white m-0">. All rights reserved</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;