import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import axios from "axios";
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { FaInfoCircle } from "react-icons/fa";

 const DateTimeFormat = (dateString) => {
  const date = new Date(dateString);
  const options = { weekday: 'short' };
  const dayOfWeek = date.toLocaleDateString('en-US', options);
  const monthOptions = { month: 'long' };
  const month = date.toLocaleDateString('en-US', monthOptions);
  const year = date.getFullYear();
  return `${dayOfWeek}, ${month} ${year}`;
};


const Vaccancy = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const fetchUsers = async () => {
    try{
      const data = localStorage.getItem("passUser");
     const token = JSON.parse(data).accessToken;
     const res = await axios.get("https://api.tewostech.com/api/vacancy", {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(res.data);
      setUsers(res.data);
    } catch (error) {
      toast.error('An error occurred!', {
        position: 'top-right'
      });
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [dispatch]);

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    if (searchTerm === "") {
      setUsersPerPage(10); 
    }
    setSearchTerm(searchTerm);
  };
  
  useEffect(() => {
    if (searchTerm !== "") {
      const filteredUsers = users.filter(
        (user) =>
        user?.job_title?.toLowerCase().includes(searchTerm) ||
        user?.job_description?.toLowerCase().includes(searchTerm) ||
        user?.responsibilities?.toLowerCase().includes(searchTerm) ||
        user?.location?.toLowerCase().includes(searchTerm)||
        user?.qualifications?.toLowerCase().includes(searchTerm) ||
        user?.department?.toLowerCase().includes(searchTerm)
      );
      setUsers(filteredUsers);
      setUsersPerPage(filteredUsers.length); // Set the pagination based on the filtered results
    } else {
      fetchUsers();
    }
  }, [searchTerm]);


  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleDetailVaccany = (newsItem) => {
    const newsId = newsItem.id; 
    navigate(`/admin/vaccancy-detail/${newsId}`);
  };

  const handleUpdateVaccany = (newsItem) => {
    const newsId = newsItem.id; 
    navigate(`/admin/vaccancy-update/${newsId}`);
  }

  const handleDeleteVaccany = async (newsItem) => {
    const userConfirmation = window.confirm("Are you sure you want to delete this vacancy?");
    if (userConfirmation) {
      try {
        const data = localStorage.getItem("passUser");
        const token = JSON.parse(data).accessToken;
        await axios.delete(`https://api.tewostech.com/api/vacancy/${newsItem.id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        toast.success('Vacancy deleted successfully!', {
          position: 'top-right'
        });
        fetchUsers(); // Refresh the list after deletion
      } catch (error) {
        toast.error('An error occurred while deleting the vacancy!', {
          position: 'top-right'
        });
      }
    }
  };

  const indexOfLastUser = (currentPage + 1) * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <div class="grid">
      <h3 className='mb-3 text-secondary'>Vaccancy</h3>
      <div class="bg-white relative shadow-md sm:rounded-lg overflow-hidden">
        <div class="grid  grid-cols-1 sm:grid-cols-2 md:gap-4 justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
          <div class="relative w-full">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
              </svg>
            </div>
            <input
              type="text"
              class="bg-gray-50 border mx-2 px-4 border-gray-300 text-black text-sm rounded-lg   block w-full pl-10 p-2 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search" required=""
              aria-label="filterbox"
              aria-describedby="basic-addon1"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>

        </div>
        <div class="overflow-x-auto">
          <table className="table caption-top table-striped border-1 table-hover table-bordered">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Title</th>
                <th scope="col">Description</th>
                {/* <th scope="col">Location</th> */}
                {/* <th scope="col">Role</th> */}
                <th scope="col">Salary</th>
                {/* <th scope="col">Qualifications</th>
                <th scope="col">Department</th>
                <th scope="col">Employee Type</th> 
                <th scope="col">Contact email</th>
                <th scope="col"> StartDate</th> */}
                <th scope="col"> Deadline</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.length === 0 ? (
                <tr>
                  <td className="text-center" colSpan="4">No data</td>
                </tr>
              ) : (
                currentUsers.map((location) => (
                    <tr key={location.id}>
                      <td className="px-2 py-2 border-b">{location.id}</td>
                      <td className="px-2 py-2 border-b">{location.job_title}</td>
                      <td className="px-2 py-2 border-b">{location.job_description}</td>
                      {/* <td className="px-4 py-2 border-b">{location.location}</td> */}
                      {/* <td className="px-4 py-2 border-b">{location.responsibilities}</td> */}
                      <td className="px-2 py-2 border-b">{location.salary_min} - {location.salary_max}</td>
                      {/* <td className="px-4 py-2 border-b">{location.qualifications}</td>
                      <td className="px-4 py-2 border-b">{location.department}</td>
                      <td className="px-4 py-2 border-b">{location.employment_type}</td>
                      <td className="px-4 py-2 border-b">{location.contact_email}</td>
                      <td className="px-4 py-2 border-b">{location.created_at}</td> */}
                      <td className="px-2 py-2 border-b">{DateTimeFormat(location.application_deadline)}</td>
                      <td className="px-2 py-2 border-b">
                      <div className="flex gap-2">
                      <button className="text-blue-400" onClick={(event) => handleUpdateVaccany(location)}> <FaEdit/> </button>
                      <button className="text-yellow-600" onClick={(event) => handleDetailVaccany(location)}> <FaInfoCircle/> </button>
                      <button className="text-red-500" onClick={(event) => handleDeleteVaccany(location)}> <MdDelete/> </button>
                      </div>
                    </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>
          <ReactPaginate
            breakLabel=""
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={Math.ceil(users.length / usersPerPage)}
            previousLabel="< Previous"
            renderOnZeroPageCount={null}
            containerClassName="pagination justify-content-center"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
          />
        </div>

      </div>
    </div>
  );
};

export default Vaccancy;