import React from "react";
import "../../styles/about/about.css";
import AboutBg from "../../Assets/About-bg.png";
import Right from "../../Assets/Right.png";
import SecondAbout from "../../Assets/about2.png";
import Fade from "react-reveal/Fade";
import "../../styles/about/aboutteam.css";
import Team1 from "../../Assets/default.png";
import Team2 from "../../Assets/avater (2).png";
import Team3 from "../../Assets/avater (3).png";
import Team4 from "../../Assets/avater (4).png";
import TedyImg from "../../Assets/FH1A5911.png";

const AboutMain = () => {
  return (
    <div>
      <section>
        <div
          className="About__Banner"
          style={{
            backgroundImage: `url(${AboutBg})`,
          }}
        >
          <Fade bottom duration={1500}>
            <div className="About__Container">
              <div className="First ">
                {/* <h2>Creative Services For Boost Your Business Growth</h2> */}
                {/* <h2>LET US TELL YOU WHO WE ARE & WHAT WE DO</h2> */}
                <h2>Let Us tell you who we are & what we Do</h2>
              </div>
            </div>
          </Fade>
        </div>
        {/* ================Main__Content================== */}

        <div className="Team__Text ">
            Our <span className="Team__span mt-5">Team </span>
          </div>
          <div className="Header ">
            <h1>BOARD MEMBERS</h1>
            {/* <p>
              Praesent sagittis eros in convallis rutrum. Donec auctor nibh
              justo. Vestibu <br></br>tincidunt, libero sit amet vestibulum
              euismod, ex nisl imperdiet leo.
            </p> */}
          </div>
          {/* =============Avater================= */}
          <div className="Team__container">
            <div>
              <img src={TedyImg} alt="" style={{ borderRadius: '135px' , width: '270px' , height : '270px' }} />
              <h3>Tewodros Atnafu</h3>
              <p>CEO</p>
            </div>
            <div>
              {" "}
              <img src={Team1} alt="" style={{ borderRadius: '135px' , width: '270px' , height : '270px' }} />
              <h3>Selam T/haimanot</h3>
              <p>COO</p>
            </div>
            <div>
              <img src={Team1} alt="" style={{ borderRadius: '135px' , width: '270px' , height : '270px' }} />
              <h3>Samrawit Atnafu</h3>
              <p>Project Lead</p>
            </div>
            <div>
              <img src={Team1} alt="" style={{ borderRadius: '135px' , width: '270px' , height : '270px' }} />
              <h3>Tekalegne Defar</h3>
              <p>Technical Lead</p>
            </div>
          </div>
      </section>
    </div>
  );
};

export default AboutMain;
